<template>
  <div class="header-box">
    <div class="all_header_box">
      <div class="sider_left">
        <!-- 面包屑 -->
        <span class="title">
          <router-link
            class="crumbs_item"
            tag="a"
            to="/admin/Examination/TestPaperManage"
            >试卷管理 /
          </router-link>
          <router-link
            v-if="!paperId"
            class="crumbs_item"
            tag="a"
            to="/admin/Examination/manualGear"
            >手动组卷 /
          </router-link>
          <span class="crumbs_item crumbs_last">试卷预览</span>
        </span>
      </div>
    </div>
    <div class="portion" v-show="redioList.length">
      <div class="">
        <span class="portion_a">单选题 </span>
        <span class="portion_a"
          >每题
          <a-input-number id="inputNumber" v-model="redioValue" :min="0" />
          分</span
        >
      </div>
      <div class="portion_list" v-for="(item,index) in redioList" :key="index">
        <div class="portion_dlex">
          <span
            >{{index + 1}}、{{item.name?item.name:item.title}}</span
          >
          <a-button type="link" @click="delItem('redioList',index)"> 移除 </a-button>
          <a-button type="link" v-show="index !==0" @click="moveUp('redioList',index)"> 上移 </a-button>
          <a-button type="link" v-show="index !== redioList.length-1" @click="moveDown('redioList',index)"> 下移 </a-button>
        </div>
        <div class="optionbox">
          <span class="option" v-for="(ite,ind) in item.optionList" :key="ind"
            >{{letters[ind]}}、{{ite.name}}<a v-show="ite.isCorrect">【正确答案】</a></span
          >
        </div>
      </div>
    </div>
    <div class="portion" v-show="checkedList.length">
      <div class="">
        <span class="portion_a">多选题 </span>
        <span class="portion_a"
          >每题
          <a-input-number id="inputNumber" v-model="checkedValue" :min="0" />
          分</span
        >
      </div>
      <div class="portion_list" v-for="(item,index) in checkedList" :key="index">
        <div class="portion_dlex">
          <span
            >{{redioList.length + index + 1}}、{{item.name?item.name:item.title}}</span
          >
          <a-button type="link" @click="delItem('checkedList',index)"> 移除 </a-button>
          <a-button type="link" v-show="index !==0" @click="moveUp('checkedList',index)"> 上移 </a-button>
          <a-button type="link" v-show="index !== checkedList.length-1" @click="moveDown('checkedList',index)"> 下移 </a-button>
        </div>
        <div class="optionbox">
          <span class="option" v-for="(ite,ind) in item.optionList" :key="ind"
            >{{letters[ind]}}、{{ite.name}}<a v-show="ite.isCorrect">【正确答案】</a></span
          >
        </div>
      </div>
    </div>
    <div class="portion" v-show="switchList.length">
      <div class="">
        <span class="portion_a">判断题 </span>
        <span class="portion_a"
          >每题
          <a-input-number id="inputNumber" v-model="switchValue" :min="0" />
          分</span
        >
      </div>
      <div class="portion_list" v-for="(item,index) in switchList" :key="index">
        <div class="portion_dlex">
          <span>{{redioList.length + checkedList.length + index + 1}}、{{item.name?item.name:item.title}}</span>
          <a-button type="link" @click="delItem('switchList',index)"> 移除 </a-button>
          <a-button type="link" v-show="index !==0" @click="moveUp('switchList',index)"> 上移 </a-button>
          <a-button type="link" v-show="index !== switchList.length-1" @click="moveDown('switchList',index)"> 下移 </a-button>
        </div>
        <div class="optionbox">
          <span class="option" v-for="(ite,ind) in item.optionList" :key="ind"
            >{{letters[ind]}}、{{ite.name}}<a v-show="ite.isCorrect">【正确答案】</a></span
          >
        </div>
      </div>
    </div>
    <div class="">
      <a-button type="primary" class="btn" @click="showModal"
        >确认组卷</a-button
      >
      <a-button @click="back">返回</a-button>
      <a-button style="margin-left:10px;" @click="$router.push('/admin/Examination/TestPaperManage')">取消</a-button>
    </div>
    <a-modal
      v-if="visible"
      title="确认组卷"
      :width="372"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div>
        <div class="dlex">
          <span>试卷名称：</span>
          <a-input style="width: 179px" v-model="paperTitle" placeholder="请输入试卷名称" />
        </div>
        <div class="dlex">
          <span>该套试卷中，共有 <a>{{totalNum}}</a> 道题，总分为 <a>{{totalGrade}}</a> 分</span>
        </div>
        <div class="dlex">
          <span>
            （单选题共 <a>{{redioList.length}}</a> 道，多选题共 <a>{{checkedList.length}}</a> 道，判断题共
            <a>{{switchList.length}}</a> 道）</span
          >
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      paperTitle: "",
      ModalText: "Content of the modal",
      visible: false,
      confirmLoading: false,
      letters:['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'],
      checkedList: [], // 多选
      redioList: [], // 单选
      switchList:[], // 判断
      checkedValue: 1,
      redioValue: 1,
      switchValue: 1,
      mode:null, // 组卷类型
      category:null, // 大类
      courseId:null, // 课程id
      paperId:null, // 组卷id
      status:null, // 是否启用状态
      totalGrade:0, // 总数
    };
  },
  // 事件处理器
  methods: {
    back(){
      this.$router.push({
        path:'/admin/Examination/manualGear',
        query:{
          paperId:this.paperId, // 组卷id
          category:this.category, // 大类
          courseId:this.courseId, // 课程id
          libraryIds:this.libraryIds, // 试题列表
          checkedValue:this.checkedValue, // 多选分数
          redioValue:this.redioValue, // 单选分数
          switchValue:this.switchValue, // 判断分数
        }
      })
    },
    moveUp(e,index){
      let arr = this[e].splice(index,1);
      this[e].splice(index-1,0,arr[0]);
    },
    moveDown(e,index){
      let arr = this[e].splice(index,1);
      this[e].splice(index+1,0,arr[0]);
    },
    delItem(e,index){
      this[e].splice(index,1);
    },
    showModal() {
      if(!this.totalNum){
        return this.$message.error('请先选择试题！')
      }
      this.visible = true;
    },
    // 获取组卷详情
    getPaperDetail(){
      this.$ajax({
        url:'/hxclass-management/exam/group/get',
        method:"get",
        params:{
          paperId:this.$route.query.paperId
        }
      }).then((res)=>{
        if(res.code == 200 && res.success){
          if(!!this.$route.query.checkedValue){
            this.checkedValue = this.$route.query.checkedValue
          }else{
            this.checkedValue = res.data.multipleScore;
          }
          if(!!this.$route.query.redioValue){
            this.redioValue = this.$route.query.redioValue
          }else{
            this.redioValue = res.data.radioScore;
          }
          if(!!this.$route.query.switchValue){
            this.switchValue = this.$route.query.switchValue
          }else{
            this.switchValue = res.data.judgeScore;
          }
          this.paperTitle = res.data.name;
          this.mode = res.data.mode;
          this.category = res.data.category;
          this.courseId = res.data.courseId;
          this.paperId = res.data.paperId;
          this.status = res.data.status;
          if(this.$route.query.libraryIds){
            this.libraryIds = this.$route.query.libraryIds;
            this.getQueryPaperList();
          }else{
            this.getPaperList();
          }
        }else{
          this.$message.warning(res.message);
        }
      })
    },
    // 获取试题列表
    getPaperList(){
      this.$ajax({
        url:'/hxclass-management/exam/group/sub/paper/list',
        method:'get',
        params:{
          paperId:this.paperId
        }
      }).then((res)=>{
        if(res.code == 200 && res.success){
          this.checkedList = res.data.checkedList;
          this.redioList = res.data.redioList;
          this.switchList = res.data.switchList;
          this.libraryIds = [...res.data.checkedList,...res.data.redioList,...res.data.switchList].map(item=>{
            return item.libraryId;
          }).join(',')
          this.totalGrade = this.redioList.length * this.redioValue + this.checkedList.length * this.checkedValue + this.switchList.length * this.switchValue;
        }else{
          this.$message.warning(res.message);
        }
      })
    },
    getQueryPaperList(){
      this.$ajax({
        url:'/hxclass-management/exam/group/sub/preview/list',
        method:"post",
        params:{
          libraryIds:this.$route.query.libraryIds
        }
      }).then((res)=>{
        if(res.code == 200 && res.success){
          this.checkedList = res.data.checkedList;
          this.redioList = res.data.redioList;
          this.switchList = res.data.switchList;
          this.totalGrade = this.redioList.length * this.redioValue + this.checkedList.length * this.checkedValue + this.switchList.length * this.switchValue;
        }else{
          this.$message.warning(res.message);
        }
      })
    },
    handleOk(e) {
      if(!this.paperTitle){
        return this.$message.warning('请先填写试卷名称');
      }
      this.confirmLoading = true;
      let arr = [];
      this.redioList.map((item,index)=>{
        arr.push(item.libraryId);
      })
      this.checkedList.map((item,index)=>{
        arr.push(item.libraryId);
      })
      this.switchList.map((item,index)=>{
        arr.push(item.libraryId);
      })
      this.$ajax({
        url:this.paperId?'/hxclass-management/exam/group/edit':'/hxclass-management/exam/group/save',
        method:this.paperId?"put":"post",
        params:{
          paperId:this.paperId, // 组卷id
          category:this.category, // 大类
          courseId:this.courseId, // 课程id
          status: 0, // 状态 1.启用 0.停用
          mode:2, // 1.自动组卷 2.手动组卷
          multipleScore:this.checkedValue, // 多选题分数
          judgeScore:this.switchValue, // 判断题分数
          name:this.paperTitle, //	组卷名称
          quesNum:this.totalNum, // 题数
          radioScore:this.redioValue, // 单选题分数
          subJson:arr.join(','), // 手动组卷json
          totalScore:this.totalGrade, // 总分
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.visible = false;
          this.confirmLoading = false;
          this.$router.push('/admin/Examination/TestPaperManage')
        }else{
          this.$message.warning(res.message);
        }
      })
    },
    handleCancel(e) {
      // console.log("Clicked cancel button");
      this.visible = false;
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    if(!!this.$route.query.paperId){
      this.getPaperDetail(); // 获取组卷详情
    }else if(this.$route.query.libraryIds){
      this.libraryIds = this.$route.query.libraryIds;
      if(!!this.$route.query.checkedValue){
        this.checkedValue = this.$route.query.checkedValue
      }else{
        this.checkedValue = 1;
      }
      if(!!this.$route.query.redioValue){
        this.redioValue = this.$route.query.redioValue
      }else{
        this.redioValue = 1;
      }
      if(!!this.$route.query.switchValue){
        this.switchValue = this.$route.query.switchValue
      }else{
        this.switchValue = 1;
      }
      this.getQueryPaperList();
      this.category = this.$route.query.category;
      this.courseId = this.$route.query.courseId;
    }
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {
    totalNum(){
      return this.redioList.length + this.checkedList.length + this.switchList.length;
    },
    // totalGrade(){
    //   return this.redioList.length * this.redioValue + this.checkedList.length * this.checkedValue + this.switchList.length * this.switchValue;
    // }
  },
  // 自定义的侦听器
  watch: {
    redioValue(newVal,oldVal){
      // console.log(newVal)
      // console.log(this.redioList.length * newVal,'单选分数')
      // console.log(this.checkedList.length * this.checkedValue,'多i选分数')
      // console.log(this.switchList.length * this.switchValue,'判读分数')
      this.totalGrade = this.redioList.length * newVal + this.checkedList.length * this.checkedValue + this.switchList.length * this.switchValue
      // console.log(this.totalGrade)
    },
    checkedValue(newVal,oldVal){
      // console.log(newVal)
      this.totalGrade = this.redioList.length * this.redioValue + this.checkedList.length * newVal + this.switchList.length * this.switchValue
      // console.log(this.totalGrade)
    },
    switchValue(newVal,oldVal){
      // console.log(newVal)
      this.totalGrade = this.redioList.length * this.redioValue + this.checkedList.length * this.checkedValue + this.switchList.length * newVal
      // console.log(this.totalGrade)
    },
  },
};
</script>

<style lang="less" scoped>
.btn {
  margin-right: 24px;
}
.header-box {
  width: 100%;
  min-height: 45px;
  border-bottom: 1px solid #eeeeee;
  overflow: hidden;
  padding: 16px;

  .left-box {
    float: left;
    margin-top: 7px;
    width: 150px;
    .border {
      display: inline-block;
      margin-right: 2px;
      width: 2px;
      height: 10px;
      background: #3681f0;
      vertical-align: middle;
    }
    .title {
      display: inline-block;
      font-size: 16px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 19px;
    }
    .menu {
      display: inline-block;
    }
  }
  .right-box {
    float: right;
    width: calc(100% - 150px);
    .input {
      float: right;
      .tag {
        margin-right: 2px;
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 14px;
      }

      .ant-input,
      .ant-calendar-picker {
        margin: 0 24px 14px 0;
        width: 240px;
        height: 30px;
      }

      .ant-select {
        margin: 0 24px 14px 0;
        width: 193px;
      }

      /deep/.ant-select-selection--single {
        height: 30px;
      }
    }
  }
}
.portion {
  width: 100%;
  padding: 30px;
  .portion_a {
    font-family: PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 16px;
  }
  .portion_dlex {
    display: flex;
    align-items: center;
    span {
      flex: 1;
    }
  }
  .portion_list {
    margin-top: 20px;
    .optionbox {
      padding: 16px 14px;
      display: flex;
      flex-wrap: wrap;
      span {
        margin: 12px 0;
        width: 50%;
      }
    }
  }
}
.dlex {
  display: flex;
  margin: 0 auto;
  align-items: center;
  margin-bottom: 14px;
  span {
    font-size: 12px;
    font-family: PingFang SC;
    color: #000000;
  }
}
</style>
